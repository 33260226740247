import Swiper from 'swiper';
import Navigation from 'swiper/modules/navigation.mjs';
import Pagination from 'swiper/modules/pagination.mjs';
import Autoplay from 'swiper/modules/autoplay.mjs';
import FreeMode from 'swiper/modules/free-mode.mjs';
import Parallax from 'swiper/modules/parallax.mjs';
import gsap from 'gsap';

import SizeSVG from './functions/sizeSVG';
import customSelect from './functions/select-custom';
import toggleFn from './functions/toggle';

import './contact';
import isMobile from 'ismobilejs';
import accordionFn from './functions/accordion';

const is_mobile = isMobile().phone || window.innerWidth <= 999;

let lastScrollTop = 0;

window.addEventListener('scroll', (e) => {
    const st = window.pageYOffset || document.documentElement.scrollTop;

    if (st > lastScrollTop) {
        // downscroll code
        window.scrollDirection = 'down';
    } else {
        // upscroll code
        window.scrollDirection = 'up';
    }

    lastScrollTop = st <= 0 ? 0 : st;
});

window.addEventListener('DOMContentLoaded', () => {
    //SizeSVG();
    customSelect();
    initSlider();
    accordionFn();
    toggleFn();
    switchHandle();
    filterHandle();
    initMap();

    if (!is_mobile) {
        headerHandle();
        footerHandle();
    }
});

const switchHandle = () => {
    const switches = document.querySelectorAll('.switch--wrapper');

    [...switches].map(item => {
        const inner = item.querySelector('.switch--inner')
        const radios = item.querySelectorAll('input');

        inner.addEventListener('click', () => {
            const radios = item.querySelectorAll('input:not(:checked)');

            [...radios].map(input => {
                input.nextElementSibling.click();
            });
        });

        [...radios].map(input => {
            const form = input.closest('form');

            if (input) {
                input.addEventListener('change', () => {
                    setTimeout(() => form.submit(), 200);
                });
            }
        });


    });
};

const initSlider = () => {
    const sliders = document.querySelectorAll('[data-swiper]');

    Object.values(sliders).map(slider => {
        const timer = 6000;

        console.log(slider.dataset.swiperMobile !== 'false' && is_mobile);
        console.log(slider.dataset.swiperMobile !== 'false', is_mobile);
        if (slider.dataset.swiperMobile === 'false' && is_mobile) {
            const remove_slides = document.querySelectorAll('[data-hide-mobile]');
            console.log(remove_slides);

            [...remove_slides].map(item => item.remove());
            return false;
        }

        const sliderOptions = {
            modules: [Navigation, Pagination, Autoplay, Parallax, FreeMode],
            slidesPerView: parseFloat(slider.dataset.swiperSlidesPerView) || 'auto',
            centeredSlides: slider.dataset.swiperCentered !== 'false',
            parallax: true,
            freeMode: slider.dataset.swiperFreemode === 'true',
            initialSlide: slider.dataset.swiperInitialSlide || 0,
            loop: slider.dataset.swiperLoop === 'true',
            speed: parseFloat(slider.dataset.swiperSpeed) || 1000,
            allowTouchMove: slider.dataset.swiperAllowMove !== 'false',
            autoplay: slider.dataset.swiperAutoplay !== 'false' ? {
                delay: parseFloat(slider.dataset.swiperTimer) || timer,
                pauseOnMouseEnter: true,
            } : false,
            autoHeight: slider.dataset.swiperHeight === 'true',
            navigation: slider.dataset.swiperNavigation !== 'false' ? {
                prevEl: slider.dataset.swiperPrev || '.swiper-button-prev', nextEl: slider.dataset.swiperNext || '.swiper-button-next',
            } : false,
            pagination: slider.dataset.swiperPagination ? {
                el: slider.dataset.swiperPaginationElement || '.swiper-pagination',
                type: slider.dataset.swiperPaginationType || 'progressbar',
            } : false,
        };

        if (slider.dataset.swiperPagination) {
            console.log(slider.dataset.swiperPaginationElement || '.swiper-pagination');
        }

        if (slider.dataset.swiperMobile === 'false' && is_mobile) {
            return false;
        }

        let swiper = new Swiper(slider, sliderOptions);

        swiper.on('slideChange', () => {
            const fractionCurrent = slider.querySelector('.slider-fraction--current');

            if (fractionCurrent) {
                fractionCurrent.innerText = (swiper.activeIndex < 10 ? '0' : '') + (swiper.activeIndex + 1);
            }
        })

    });
};

const headerHandle = () => {
    const header = document.querySelector('.main--header');
    const headerHeight = header.getBoundingClientRect().height;

    window.addEventListener('scroll', () => {
        if (window.scrollY > headerHeight) {
            header.classList.add('stuck');
        } else {
            header.classList.remove('stuck');
        }

        if (window.scrollDirection === 'up' && window.scrollY > headerHeight) {
            header.classList.add('show');
        } else {
            header.classList.remove('show');
        }
    })
}

const footerHandle = () => {
    const footer = document.querySelector('.main--footer');
    const main = document.querySelector('main');

    const options = {
        root: null,
        rootMargin: `${footer.getBoundingClientRect().height}px`,
        threshold: 1.0,
    };

    return;
    const observer = new IntersectionObserver((entries) => {
        [...entries].map(entry => {
            if (entry.isIntersecting) {
                main.classList.add('stuck');
                if (!main.style.top) main.style.top = main.getBoundingClientRect().top + 'px';
            } else {
                main.classList.remove('stuck')
                main.removeAttribute('style')
            }
        })


    }, options);

    observer.observe(footer);
}

const filterHandle = () => {

    //RESET
    const reset = document.querySelectorAll('.filter--reset');

    [...reset].map(button => {
        const form = button.closest('form');
        const inputs = form.querySelectorAll('select:not([hidden]), input:not([hidden])');

        if (is_mobile) {
            button.classList.add('button--bordered', 'small');
            button.classList.remove('text--underline', 'text--smaller');
        }

        button.addEventListener('click', () => {
            [...inputs].map(input => {
                input.value = '';
                input.dispatchEvent(new KeyboardEvent('change'));
                input.dispatchEvent(new KeyboardEvent('keyup'));

                form.submit();
            })
        })
    })

    //MOBILE TOGGLE

    if (is_mobile) {
        const toggleButtons = document.querySelectorAll('.filter--toggle');

        [...toggleButtons].map(button => {
            button.addEventListener('click', () => {
                const form = button.closest('form');

                form.classList.toggle('toggled')
            })
        })
    }
}

const initMap = () => {
    const wrapper = document.querySelectorAll('[data-map]');

    if (wrapper) {
        [...wrapper].map(el => {
            const longlat = el.dataset.coordinates.split(',').reverse()
            const map = L.map(el).setView(longlat, 15);

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                controls: false,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(map);

            const myIcon = L.icon({
                iconUrl: '/wp-content/themes/2050now-theme/src/assets/images/marker.svg',
                iconSize: [45, 50],
                iconAnchor: [22, 50],
            });

            L.marker(longlat, {icon: myIcon}).addTo(map);
        })

    }
}