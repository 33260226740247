import isMobile from "ismobilejs";
import gsap from "gsap";
const is_mobile = isMobile().phone || window.innerWidth <= 999;

export default function SizeSVG(element) {
    if (element) {
        const svgWidth = parseFloat(element.getAttribute("width").replace('px', ''));

        if (!is_mobile && window.innerWidth < 1440) {
            gsap.set(element, {
                width: (svgWidth * 100) / 1440 + 'vw',
                height: 'auto'
            })
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    const svgs = document.querySelectorAll('svg:not(.no--resize)');
    Object.values(svgs).map(svg => SizeSVG(svg));
})
