export default function toggleFn() {
    const buttons = document.querySelectorAll('[data-toggle]');

    [...buttons].map((button, index) => {
        button.addEventListener('click', () => {
            const id = button.dataset.toggle;
            const elementsToToggle = document.querySelectorAll(`[data-toggle-element=${id}]`);

            console.log([...document.querySelectorAll(`[data-toggle].active:not([data-toggle*="${id.split('-')[0]}"])`)]);

            [...document.querySelectorAll(`[data-toggle*="${id.split('-')[0]}"].active:not([data-toggle=${id}])`)].map(item => {
                !item.closest(`[data-toggle-element="${id}"]`) ? item.classList.remove('active') : null;
            });

            [...document.querySelectorAll(`[data-toggle-element*="${id.split('-')[0]}"].toggled:not([data-toggle-element=${id}])`)].map(item => {
                !item.closest(`[data-toggle-element="${id}"]`) ? item.classList.remove('toggled') : null;
            });

            [...document.querySelectorAll(`[data-toggle=${id}]`)].map(item => item.classList.toggle('active'));

            [...elementsToToggle].map(item => {
                item.classList.toggle('toggled');

                if (id === 'search') {
                    item.querySelector('input').focus();
                }
            });
        });
    });

    window.addEventListener('click', e => {
        if (!e.target.closest('[data-toggle]') && !e.target.closest('[data-toggle-element]')) {
            [...document.querySelectorAll(`[data-toggle].active`)].map(item => {
                item.classList.remove('active');
            });

            [...document.querySelectorAll(`[data-toggle-element].toggled`)].map(item => {
                item.classList.remove('toggled')
            });
        }
    });
};

