import countries from "../../functions/countries.json";
import isMobile from 'ismobilejs';

const form = document.querySelector('#contactForm');
const is_mobile = isMobile().phone || window.innerWidth <= 999;

window.addEventListener('DOMContentLoaded', () => {
    validateInputs();

    if (form) {
        if (!is_mobile) form.style.height = form.offsetHeight + 'px';

        countryCodeHandle();
    }
})

form?.addEventListener('submit', e => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add('form--sending');

    const inputs = form.querySelectorAll(
        'input,textarea,select');
    const contactData = new FormData(form);

    Object.values(inputs).map(item => {
        if (item.getAttribute('name') === 'telephone') {
            contactData[item.getAttribute('name')] = item.closest('.input--wrapper').dataset.code + item.value;
        } else {
            contactData[item.getAttribute('name')] = item.value;
        }
    });

    const inputError = document.querySelector('.input--error');

    if (!inputError) {
        fetch('/wp-json/contact/v1/send', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactData),
        })
        .then(async resp => {
            if (resp.status === 200) {
                const inputToFlush = form.querySelectorAll(
                    'input:not(#pardot_extra_field, #lead_source, #retURL, #oid, #Form_Source__c, #utmsource__c, #fileDownload, #articleTitle),textarea,select');

                Object.values(inputToFlush).map(input => {
                    input.value = '';
                    input.classList.remove('edited')
                });

                window.scrollTo(0, 0);

                e.target.classList.remove('form--sending');
                e.target.classList.add('form--sent');
            }
        });
    } else {
        inputError.scrollIntoView();
    }
});

//Disable enter for messages
const textArea = document.querySelectorAll('textarea');
Object.values(textArea).map(el =>
    el.addEventListener('keydown', e => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            return false;
        }
    }),
);

Object.values(document.querySelectorAll('form input, form select, form textarea')).map(item => {
    const events = ['blur', 'keyup'] ;

    events.map(event => {
        item.addEventListener(event, () => {
            item.classList.toggle('edited', item.value !== '');
        })
    })
})

// Check input with patterns
const validateInputs = () => {
    const inputs = document.querySelectorAll('input[data-pattern]');

    Object.values(inputs).map(input => {
        const pattern = input.dataset.pattern;
        const regexp = new RegExp(pattern, 'g');

        input.addEventListener('blur', () => {
            if (input.value !== '') {
                input.classList.toggle('input--error', !input.value.match(regexp));
            } else {
                input.classList.remove('input--error');
            }
        });

        input.addEventListener('keyup', () => {
            if(input.value.match(regexp) || input.value === '') input.classList.remove('input--error');
        })
    })

    Object.values(document.querySelectorAll('form input, form select, form textarea')).map(item => {
        const events = ['blur'];

        events.map(event => {
            item.addEventListener(event, () => {
                item.classList.toggle('edited', item.value !== '');
            })
        })
    })
}

const countryCodeHandle = () => {
    const select = form.querySelector('select#country');
    const phone = form.querySelector('input#telephone');

    if (select) {
        select.addEventListener('change', () => {
            phone.closest('.input--wrapper').dataset.code = countries.countries[select.value].code;
        })
    }
}